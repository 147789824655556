import water from '../img/pois/water.svg';
import restaurant from '../img/pois/restaurant.svg';
import toilet from '../img/pois/toilet.svg';
import photopoint from '../img/pois/photopoint.svg';
import pin from '../img/pois/pin.svg';

function PoiDot(props) {

  const data = {
    restaurant: {color: '#FF9A03', icon: restaurant},
    water: {color: '#4A99FF', icon: water},
    toilet: {color: '#8F65DB', icon: toilet},
    photopoint: {color: '#F9424D', icon: photopoint},
    pin: {color: '#F9BB42', icon: pin},
  }

  // Helper function to get the appropriate data based on type
  function getData(type) {
    if (type && data[type]) {
      return data[type];
    }
    return data.pin; // Fall back to water if type is not found or undefined
  }

  const poiData = getData(props.type);


  return (
    <div className={'poidot'} style={{background: poiData.color}}>
        <img src={poiData.icon} />
    </div>
  );
}

export default PoiDot;
