import { useState, useRef, useEffect } from 'react';
import closeicon from "../img/close.svg";

import { translate } from '../functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';

import ioslocationicon from "../img/location-ios.png";
import androidlocationicon from "../img/location-android.png";


function ModalInstallInfo(props) {

  let { regionid, trailid } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("ios");
  const [infosopen, setinfosopen] = useState(false);

  let lang = props.lang;

  const tl = {
    headline: {
      de: "Als App speichern",
      en: "Save as App",
      it: "Salva come App"
    },
    subtext: {
      de: "Tipp: Speichere dir die Webseite auf dem Homescreen, für eine bessere Bedienung!",
      en: "Tip: Save this page on your homescreen, for a better experience!",
      it: "Suggerimento: Salva questa pagina sulla tua schermata principale, per un'esperienza migliore!"
    },
    iphone: {
      de: ["Klicke auf das 'Teilen' Symbol in der Adress-Leiste","In der erscheinenden Liste, tippe auf 'Zum Homescreen hinzufügen'", "Öffne die App"],
      en: ["Tap the Share icon in the address bar","In the pop-up list, tap 'Add to Homescreen'", "Open the app"],
      it: ["Tocca l'icona Condividi nella barra degli indirizzi","Nell'elenco che appare, tocca 'Aggiungi alla schermata principale'", "Apri l'applicazione"]
    },
    android: {
      de: ["Klicke auf das 'Mehr' Symbol","Tippe auf 'Zum Homescreen hinzufügen' oder 'App installieren'", "Öffne die App"],
      en: ["Tap the More icon","Tap 'Add to Homescreen' or 'Install App'", "Open the app"],
      it: ["Tocca l'icona Altro","Tocca 'Aggiungi alla schermata principale' o 'Installa App'", "Apri l'applicazione"]
    },
    button: {
      de: "So geht's",
      en: "See how it works",
      it: "Guarda come funziona"
    }
  }

  function close() {
    localStorage.setItem("seeninstallprompt", true);
    props.onClose();
  }


  return (
    <div className={`modalstationprogress ${props.open ? null : "modalstationprogressclosed"}`} >
      <div className="modalstationprogressbackground" onClick={() => close()}></div>
      <div className="modallocationinner modalappinstallinner" >
      <img className="modalinstallinfoicon" src={`${process.env.REACT_APP_CONTENT_URL}${props.slug}/logo.png`} />
      

      <img src={closeicon} className='modalstationprogressclose' onClick={() => close()} />
        
        <div className="modallocationinnercontent">
          <h2>{tl.headline[lang]}</h2>
          <p>{tl.subtext[lang]}</p>

        {
          infosopen ?
          <>
          <div className='tabcontainer'>
            <div className={`tabitem ${tab == "ios" && "tabitemactive"}`} onClick={() => setTab("ios")}>iPhone</div>
            <div className={`tabitem ${tab == "android" && "tabitemactive"}`} onClick={() => setTab("android")}>Android</div>
          </div>

          {
            tab == "ios" &&
            <div>
              <ol>
                <li>{tl.iphone[lang][0]}</li>
                <li>{tl.iphone[lang][1]}</li>
                <li>{tl.iphone[lang][2]}</li>
              </ol>
            </div>
            
          }
          {
            tab == "android" &&
            <div>
              <ol>
                <li>{tl.android[lang][0]}</li>
                <li>{tl.android[lang][1]}</li>
                <li>{tl.android[lang][2]}</li>
              </ol>
            </div>
          }
          </>
          :
          <div className='button-primary' style={{marginTop: 24}} onClick={() => setinfosopen(true)}>{tl.button[lang]}</div>
          }
        </div>

      </div>
    </div>
  );
}

export default ModalInstallInfo;
