// format mintes to hours, eg. 90 -> 1:30
export default function formatTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours.toString();
  const formattedMinutes = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes.toString();

  return `${formattedHours}:${formattedMinutes}`;
}

