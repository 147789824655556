import img_hike from "./img/tags/hike.svg";
import img_bike from "./img/tags/bike.svg";
import img_stroller from "./img/tags/stroller.svg";
import img_winter from "./img/tags/winter.svg";

const tags = {
  easy: {
    translations: {
      de: "Einfach",
      en: "Easy",
      it: "Semplice"
    }
  },
  medium: {
    translations: {
      de: "Mittel",
      en: "Medium",
      it: "Medio"
    }
  },
  hard: {
    translations: {
      de: "Schwer",
      en: "Hard",
      it: "Difficile"
    }
  },
  hike: {
    icon: img_hike,
    translations: {
      de: "Wandern",
      en: "Hiking",
      it: "Escursionismo"
    }
  },
  winterhike: {
    translations: {
      de: "Winterwandern",
      en: "Winter hiking",
      it: "Sentiero invernale"
    }
  },
  snowshoeing: {
    translations: {
      de: "Schneeschuh",
      en: "Snowshoeing",
      it: "Sentiero ciaspole"
    }
  },
  bike: {
    icon: img_bike,
    translations: {
      de: "Radfahren",
      en: "Biking",
      it: "Ciclismo"
    }
  },
  winter: {
    icon: img_winter,
    translations: {
      de: "Winter",
      en: "Winter",
      it: "Inverno"
    }
  },
  stroller: {
    icon: img_stroller,
    translations: {
      de: "Kinderwagen-freundlich",
      en: "Stroller-friendly",
      it: "Passeggino-amichevole"
    }
  }
};

export default tags;