import { useState, useRef, useEffect } from 'react';
import './App.css';
import loadingicon from "./img/loading.png";


function LoadingScreen(props) {

  const creatUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}createAnonymousUser.php`);

      const data = await response.json();
      
      if(data.uuid != undefined) {
        localStorage.setItem("uuid", data.uuid);
        window.location.reload();
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if(props.newuser) {
      creatUser();
    }
  })
  
  return (
    <div className="loadingscreen">
      <img className='loadingicon' src={loadingicon} />
    </div>
  );
}

export default LoadingScreen;
