import { useState, useEffect, useRef } from 'react';
import './App.css';
import './traildetail.css';

import badge1 from "./img/badge1.png";
import logo4 from "./img/logo4.svg";
import closeicon from './img/close-white.svg';
import loadingsmall from "./img/loadingsmall.svg"

import iconstation from "./img/detail-station.svg";
import iconlength from "./img/detail-length.svg";
import icontime from "./img/detail-time.svg";
import iconintensity from "./img/detail-intensity.svg";

import markerbg from "./img/markerbg.png";

import pinplaceholder from "./img/pin-placeholder.png";

import playicon from "./img/play.svg";

import getIntensity from './functions/getIntensity';
import getIntensityIcon from './functions/getIntensityicon';

import badgeshine from "./img/badgeshine.png";

import formatTime from './functions/formatTime';
import formatDistance from './functions/formatDistance';
import getDayMonth from './functions/getDayMonth';

import Map, { Marker, Source, Layer } from 'react-map-gl/maplibre';

import StationImage from './components/StationImage';

import Tag from './components/Tag';

//import tl from './translations';
import { translate } from './functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';
import Badge from './components/Badge';

import Trailer from './components/Trailer';

function Accordeon() {
  const [active, setactive] = useState(false);

  return (
    <div style={{ padding: 20 }} onClick={() => setactive(!active)}>i am {active ? "open" : "closed"}</div>
  )
}

function TrailDetail(props) {

  let { regionid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [traileropen, setTraileropen] = useState(false);

  const [loadingrestart, setLoadingRestart] = useState(false);

  const [geojson, setgeojson] = useState(null);


  const divRef = useRef(null);
  const mapref = useRef(null);

  let options = JSON.parse(props.infos.options);
  let tags = JSON.parse(props.infos.tags);

  let stations = [
    {
      title: "Das Zirporchester",
      type: "Spiel",
      icon: require("./img/badge1.png")
    },
    {
      title: "Hungriger Auerhahn",
      type: "Video",
      icon: require("./img/badge2.png")
    },
    {
      title: "Schmetterlingschaos",
      type: "Erzählung",
      icon: require("./img/badge3.png")
    },
    {
      title: "Die alte Hexen",
      type: "Spiel",
      icon: require("./img/badge1.png")
    },
    {
      title: "Das Wasserrad",
      type: "Video",
      icon: require("./img/badge2.png")
    }
  ]

  const fetchgeojson = async () => {
    fetch(
      `${process.env.REACT_APP_CONTENT_URL}${regionid}/${props.infos.id}/route.geojson`
    )
      .then(resp => resp.json())
      .then(json => setgeojson(json))
      .catch(err => console.error('Could not load data', err)); // eslint-disable-line
  };

  const lineStyle = {
    id: 'line',
    type: 'line',
    paint: {
      "line-color": "#71A8FB",
      "line-width": 5,
    },
    layout: {
      "line-cap": "round"
    }
  };

  useEffect(() => {
    if (props.open) {
      fetchData();
      fetchgeojson();
      divRef.current.scrollTo({
        top: 0,
      });
    }
  }, [props.open])

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}traildetail.php?trailid=${props.infos.id}&lang=${props.lang}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const startagain = async () => {
    setLoadingRestart(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}startagain.php`, {
        method: 'POST',
        body: JSON.stringify({
          regionid: regionid,
          trailid: props.infos.id
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      });
      const res = await response.json();
      if (res?.success) {
        startMap();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingRestart(false);
    }
  };


  function formatAddressString(address) {
    const addressParts = address.split(',').map(part => part.trim());
    const formattedAddress = addressParts.join('\n');
    return formattedAddress;

    console.log(formattedAddress);
  }

  function startMap() {
    navigate(`/${regionid}/map/${props.infos.id}`);
  }

  function calculateBounds(stations) {
    if (!stations || stations.length === 0) {
      // Return some default values or handle the case when there are no trails
      return { minlong: 0, maxlong: 0, minlat: 0, maxlat: 0 };
    }

    let minlong, maxlong, minlat, maxlat;

    stations.forEach(station => {

      // Extract lat and long
      const lat = station.lat;
      const long = station.long;

      // Initialize min and max values
      if (minlong === undefined || maxlong === undefined || minlat === undefined || maxlat === undefined) {
        minlong = maxlong = long;
        minlat = maxlat = lat;
      } else {
        // Update min and max values for longitude
        minlong = Math.min(minlong, long);
        maxlong = Math.max(maxlong, long);

        // Update min and max values for latitude
        minlat = Math.min(minlat, lat);
        maxlat = Math.max(maxlat, lat);
      }
    });

    return { minlong, maxlong, minlat, maxlat };
  }

  useEffect(() => {
    if (data.length > 0 && props.open && mapref.current != null) {
      if (data.length > 1) {
        const bounds = calculateBounds(data);
        mapref.current?.fitBounds(
          [
            [bounds.minlong, bounds.minlat],
            [bounds.maxlong, bounds.maxlat]
          ],
          { padding: { top: 100, bottom: 40, left: 60, right: 60 }, duration: 1000 }
        );
      } else {
        mapref.current?.flyTo({ center: [data[0].long, data[0].lat], zoom: 12 });
      }
    }
  }, [data, props.open, mapref])

  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const yOffset = divRef.current.scrollTop;
      //console.log(yOffset);
      setScrollOffset(yOffset);
    };

    if (divRef.current && props.open) {
      divRef.current.addEventListener('scroll', handleScroll);
    }

    if (divRef.current && !props.open) {
      divRef.current.removeEventListener('scroll', handleScroll);
    }

    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [props.open]); // Empty dependency array, runs only once after initial render



  return (
    <div className={`page-traildetail ${props.open ? null : "page-traildetailclosed"}`}>

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>

      <div className='traildetailscrollcontainer' ref={divRef}>

        <div className='trailer' style={{ /*backgroundImage: `url(${props?.infos?.image})`*/ }} onClick={() => options.usetrailer ? setTraileropen(true) : null}>
          <img src={props?.infos?.image} className='trailerbackgroundimage' style={{top: scrollOffset > 0 ? scrollOffset/4 : 0}}/>
          {options.usetrailer && <div className='trailerplayicon'><img src={playicon} /></div>}
          {props?.infos?.imagecredits != "" && <div className='imagecredits'>{translate("imagecredit", props.lang)}: {props?.infos?.imagecredits}</div>}
        </div>

        {/* Second sticky bottom button
      <div className='startbuttonbottombox'>
        <div className='wrapper'>
          <div className='button-primary'>Jetzt starten</div>
        </div>
      </div>
      */}

        <div className='wrapper' style={{ marginTop: 32, marginBottom: 56 }}>



          <div className='trailtitlebox'>
            <h2>{props.infos.title}</h2>
            <p>{props.infos.subtitle}</p>
          </div>

          {
            props.infos.done &&
            <div className='traildetailbadgebox'>
              <Badge img={props.infos.badge} active={true} />
              <div className='traildetailbadgeboxtext'>
                <h2>{translate("youdidit", props.lang)}</h2>
                <p>{translate("youdidittext", props.lang).replace('${1}', getDayMonth(props.infos.donedate))}</p>
                <div className='traildetailbadgeboxbutton clickable' onClick={() => props.openshare()}>{translate("sharewithfriends", props.lang)}</div>
              </div>
            </div>

          }

          <div onClick={() => props.infos.done ? startagain() : startMap()} className='button-primary' style={{ marginTop: 28, marginBottom: 24 }}>{loadingrestart ? <img src={loadingsmall} className='loadingsmall' /> : (props.infos.done ? translate("startagain", props.lang) : translate("startnow", props.lang))}</div>


          <div className='trailinfocontainer'>
            <p><span>{formatDistance(props.infos.distance)}</span> km</p>
            <p><span>{props.infos.elevation}</span> hm</p>
            <p><span>{formatTime(props.infos.time)}</span> h</p>
          </div>

          {tags &&
            <div className='detailtagcontainer'>
              {
                Array.isArray(tags) && tags.map((tag, index) => <Tag key={index} tag={tag.tag} lang={props.lang} />)
              }
            </div>
          }


          <p className='detaildescription'>
            {props.infos.description}
          </p>

          <div className='detail-section-anfahrt' style={{ marginTop: 40 }}>
            <h2>{translate("arrival", props.lang)}</h2>
            <div className='detail-section-anfahrt-textcont'>{props?.infos?.startaddress.split(',').map((part, i) => <p key={i}>{part.trim()}</p>)}</div>

            <div onClick={() => window.open(`https://maps.google.com/?q=${JSON.parse(props?.infos?.mapbadgepos).lat},${JSON.parse(props?.infos?.mapbadgepos).long}`, '_self')} className='button-secondary' style={{ marginTop: 20 }}>{translate("route", props.lang)}</div>
          </div>

        </div>

        {/*<img src={require("./img/map-mock.png")} style={{width: '100%'}} />*/}




        <div>

          <Map
            ref={mapref}
            initialViewState={{
              longitude: data[0]?.long,
              latitude: data[0]?.lat,
              zoom: 12
            }}
            style={{ width: '100vw', height: 300 }}
            mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${process.env.REACT_APP_MAP_KEY}`}
          >
            {
              data?.map((obj, index) => {
                return (<Marker key={index} longitude={obj.long} latitude={obj.lat} anchor="bottom">
                  <div className='stationmarker'>
                    <img src={markerbg} className='markerbg' />
                    <StationImage image={obj.image} style={{ width: 52, height: 52, position: 'absolute', top: 7 }} />
                  </div>
                </Marker>)
              })
            }

            {geojson &&
              <Source type="geojson" data={geojson}>
                <Layer {...lineStyle} />
              </Source>
            }
          </Map>
          <div className='wrapper' style={{ marginTop: 48, marginBottom: 56 }}>
            <div className='detail-section-stationen'>
              <h2>{data.length} {data.length == 1 ? translate("station_singular", props.lang) : translate("stations", props.lang)}</h2>

              <div className='stationlistwrapper'>
                {
                  data?.map((obj, index) => (
                    <div className='stationlistitem' key={index}>
                      <StationImage image={obj.image} style={{ width: 64, height: 64, border: '3px solid #fff' }} />
                      <div className='stationlistitemtext'>
                        <p style={{ fontSize: 16, marginBottom: 2 }}>{obj.title}</p>
                        {/*<p style={{fontSize: 13, opacity: 0.7}}>{obj.type}</p>*/}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>


          </div>
        </div>


      </div>

      {options.usetrailer && <div className={`pagewrapper-trailer ${!traileropen && "pagewrapper-trailer-closed"}`}><Trailer url={props.infos.trailer} active={traileropen} onClose={() => setTraileropen(false)} /></div>}
    </div>
  );
}

export default TrailDetail;
