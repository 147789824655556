import { useState, useRef, useEffect } from 'react';
import closeicon from "../img/close-white.svg";
import star from "../img/star.svg";
import loadingsmall from "../img/loadingsmall.svg"

import { translate } from '../functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';

function ModalFeedback(props) {

  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  let { regionid, trailid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  function goBacktoHome() {
    navigate(`/${regionid}`);
  }


  const postFeedback = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}postFeedback.php`, {
        method: 'POST',
        body: JSON.stringify({
          trailid: trailid,
          rating: rating,
          feedback: feedback
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
   
      goBacktoHome()
      
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className={`modalfeedback ${props.open ? null : "modalfeedbackclosed"}`} >
      {/*<div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>*/}
      <div className="modalfeedbackinner">
        <h2>{translate("feedback_headline", props.lang)}</h2>
        <div className="modalfeedbackstarcontainer">
          {
            [...Array(5)].map((obj, index) => <img key={index} src={star} style={{opacity: index+1 <= rating ? 1 : 0.3}} onClick={() => setRating(index+1)} />)
          }
        </div>
        {rating == 0 ? null : <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} className='feedbacktext' placeholder={rating == 5 ? translate("feedback_fivestars", props.lang) : translate("feedback_improve", props.lang)}></textarea>}
        <div className='button-primary' style={{marginTop: 24}} onClick={() => rating == 0 ? goBacktoHome() : postFeedback()}>{rating == 0 ? translate("skip", props.lang) : (loading ? <img src={loadingsmall} className='loadingsmall'  /> : translate("sendfeedback", props.lang))}</div>
      </div>
    </div>
  );
}

export default ModalFeedback;
