// Audio Player with slider

import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player'

import playicon from "../img/play_dark.svg";
import pauseicon from "../img/pause_dark.svg";

import seekback from "../img/replay_10.svg";
import seekforward from "../img/forward_10.svg";

function ContentAudio(props) {

  const ref = useRef(null)

  const [playing, setplaying] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!props.active) {
      setplaying(false);
    }
  }, [props.active])

  const handleSeekChange = e => {
    setSeconds(parseFloat(e.target.value))
    ref.current.seekTo(parseFloat(e.target.value));
  }

  return (
    <div className="page-content-audio" style={{backgroundColor: props.content?.backgroundcolor ? props.content.backgroundcolor : '#222'}}>
      <ReactPlayer
        ref={ref}
        playing={playing}
        url={props.baseurl + props.content.url}
        style={{ display: 'none' }}
        onProgress={(e) => setSeconds(e.played)}
        onEnded={() => setplaying(false)}
        playsinline
      />

      <div className='audiocontrols' style={{flexDirection: 'column'}}>
        <div onClick={() => setplaying(!playing)} className={'audioplayicon clickable'}><img src={playing ? pauseicon : playicon} /></div>
        <input
        type='range' min={0} max={0.999999} step='any'
        value={seconds}
        onChange={handleSeekChange}
        className='audiobar'
      />
      </div>

      {props.content.imageurl && <img className='audiobackground' src={props.content.imageurl} />}
      
    </div>
  );
}

export default ContentAudio;
