// Success page and Feedback

import { useState, useRef, useEffect, useMemo } from 'react';
import './station.css';
import './success.css';

import markerbg from "./img/markerbg.png";
import checkmark from "./img/checkmark.svg";
import retryimg from "./img/retry.svg";
import loadingsmall from "./img/loadingsmall.svg"

import StationImage from './components/StationImage';

import Confetti from 'react-confetti';

import { translate } from './functions/translationHelper';

import audio from './img/sounds/stationdone.wav';

import { useParams, useNavigate } from 'react-router-dom';

import { handleApiResponse } from './functions/handleApiResponse.js';

function StationSuccess(props) {

  const myAudio = useMemo(() => new Audio(audio), [audio]);

  const lang = "de";

  let { regionid, stationid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const confettiref = useRef(null);

  const data = {
    title: "Niederthai Challenge",
    stations: 9
  }

  function playAudio() {
    myAudio.play();
  }

  useEffect(() => {
    if(props.active) {
      //confettiref.current.run()
    }
  }, [props.active])

  function goBacktoMap() {
    navigate(`/${regionid}/map/${props.trailid}`);
  }


  const postData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}setStationdone.php`, {
        method: 'POST',
        body: JSON.stringify({
          regionid: regionid,
          stationid: stationid
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      if(data.code !== undefined) {
        handleApiResponse(data.code, regionid, navigate);
      } else {
        goBacktoMap()
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="page-stationsuccess">
      <Confetti
        ref={confettiref}
        run={props.active}
        numberOfPieces={60}
        style={{zIndex: 4}}
  />
      {/* Success */}
      <div className='wrapper wrapper-success'>

          <div className={`stationmarker stationmarkersuccess stationmarkeractive`}>
                <div className='stationcheckmark'><img src={checkmark} style={{width: 20}} /></div>
                <img src={markerbg} className='markerbg' style={{width: 120}} />
                <StationImage image={props.baseurl + "/image.png"} style={{width: 88, height: 88, position: 'absolute', top: 10}} />
              </div>

        <div>
          {/*<h2>Station geschafft!</h2>*/}
          <p>{translate("station_success", props.language).replace('${1}', `"${props.title}"`)}</p>
          <div className='button-primary' style={{marginTop: 28}} onClick={() => postData()}>{loading ? <img src={loadingsmall} className='loadingsmall'  /> : translate("continue", props.language)}</div>
          {props.numcontents > 0 && <div className='clickable' style={{marginTop: 28, display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 0.7}} onClick={() => props.startAgain()}><img src={retryimg} style={{width: 20, marginRight: 4}} />{translate("viewagain", props.language)}</div>}
        </div>
      </div>

      
    </div>
  );
}

export default StationSuccess;
