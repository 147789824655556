// Video Player

import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player'

import playicon from "../img/play.svg"
import closeicon from '../img/close-white.svg';

function Trailer(props) {

  const [playing, setplaying] = useState(false);
  const ref = useRef(null)

  useEffect(() => {
    if(props.active) {
      //setplaying(true);
    } else {
      setplaying(false);
      ref.current?.seekTo(0);
    }
  }, [props.active])

  function closeTrailer(e) {
    e.stopPropagation();
    setplaying(false);
    props.onClose();
  }

  return (
    <div className="page-trailer" onClick={() => setplaying(!playing)}>
      <div className='closebutton' onClick={(e) => closeTrailer(e)}><img src={closeicon} /></div>
      <ReactPlayer 
        ref={ref}
        playing={playing}
        url={props.url} 
        width='100%'
        height='100%'
        playsinline
        loop
      />

      <div className={`videoplayicon ${playing ?  "videoplayiconhidden" : null}`}><img src={playicon} /></div>
    </div>
  );
}

export default Trailer;
