import React, { useState, useEffect } from 'react';
import './App.css';
import LoadingScreen from './LoadingScreen';

import { useNavigate } from 'react-router-dom';

function RegionOverview() {

  const navigate = useNavigate();


  useEffect(() => {
    if(localStorage.getItem('region') != null) {
      navigate(`/${localStorage.getItem('region')}`)
    } else {
      window.location.href = 'https://www.wunderweg.com/';
    }
    
  }, []); 

  return (
        <LoadingScreen />
      )
}

export default RegionOverview;
