import { useState, useRef, useEffect } from 'react';
import closeicon from "../img/close.svg";

import { translate } from '../functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';

import StationImage from './StationImage';

import checkmark from "../img/checkmark.svg";

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function ModalStationProgress(props) {

  let { regionid, trailid } = useParams();
  const navigate = useNavigate();


  return (
    <div className={`modalstationprogress ${props.open ? null : "modalstationprogressclosed"}`} >
      <div className="modalstationprogressbackground" onClick={() => props.onClose()}></div>
      <div className="modalstationprogressinner" >
      <div className="mapprogressstations">
          <CircularProgressbarWithChildren 
            value={props.done} 
            maxValue={props.total}
            strokeWidth={6}
            styles={{
              root: {overflow: 'visible'},
              path: {
                stroke: 'var(--main-color)',
                strokeWidth: 8
              },
              trail: {
                stroke: '#e3e3e3',
              },
            }}
          >
          <div className="mapprogresstext"><span>{props.done}</span>/{props.total}</div>
          </CircularProgressbarWithChildren>
          
        </div>

      <img src={closeicon} className='modalstationprogressclose' onClick={() => props.onClose()} />
        
        <div className="modalstationprogressinnerlist">
          {
            props.stations?.map((obj, index) => 
              <div key={index} className='modalstationprogresscard' onClick={() => props.onSelect(index)}>
                <div style={{position: 'relative'}}>
                  {obj.done && <div className='stationcheckmark' style={{ width: 24, height: 24 }}><img src={checkmark} style={{ width: 16 }} /></div>}
                  <StationImage image={obj.image} style={{width: 56, height: 56, marginRight: 16}} />
                </div>
                <div>{obj.title}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default ModalStationProgress;
