
import pinplaceholder from "../img/pin-placeholder.png";

function StationImage(props) {

  return (
    <div style={{...props.style,  backgroundImage: `url(${pinplaceholder}` }} className='markerimginside'>
      <div style={{ backgroundImage: `url(${props?.image})` }} className='markerimginsideinner'></div>
    </div>
  );
}

export default StationImage;
