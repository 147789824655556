import { useState, useRef, useEffect, useCallback } from 'react';
import closeicon from "../img/close.svg";
import cameraicon from "../img/camera.svg";

import { translate } from '../functions/translationHelper';

import badgeshine from "../img/badgeshine.png";

import getDayMonth from '../functions/getDayMonth';

import html2canvas from 'html2canvas';

import shareicon from "../img/share.svg";


import { toPng, toBlob } from 'html-to-image';

//var domtoimage = require('dom-to-image');

import { saveAs } from 'file-saver';

import loadingsmall from "../img/loadingsmall.svg"




function ModalSocialShare(props) {

  const [file, setFile] = useState(null);
  const [imagedata, setImagedata] = useState(null);

  const imageref = useRef(null);

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
}

useEffect(() => {
  if(props.open) {
    htmlToImageConvert();
  }
}, [props.open, file])


const htmlToImageConvert = () => {
  setImagedata(null);
  html2canvas(imageref.current, { useCORS: true}).then(canvas => {
    // Get the image data URL
    const dataURL = canvas.toDataURL('image/png', 1.0);
    //console.log(dataURL);
    setImagedata(dataURL);
  });
};

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const handleShare = async () => {
  try {
    const blob = dataURItoBlob(imagedata);
    const file = new File([blob], 'socialshare.png', { type: 'image/png' });
    await navigator.share({ files: [file] });
  } catch (err) {
    if(err.name != "AbortError") {
      console.error('Error sharing:', err);
      alert(translate("socialshareerrormessage", props?.lang));
    }
  }
}

  return (
    <div className={`modalsocialshare ${props.open ? null : "modalsocialshareclosed"}`} >
      <div className="modalsocialsharebackground" onClick={() => props.onClose()}></div>
      <div className="modalsocialshareinner" >

      <div className='modalsocialshareheader'>
        <p>{translate("sharewithfriends", props?.lang)}</p>
        <img src={closeicon}  onClick={() => props.onClose()} className='clickable' />
      </div>

      {/* IMAGE TO DISPLAY */}
      <div className='wrapper' style={{maxWidth: 260}}>

      {imagedata ?
      <img className='socialshareimg' src={imagedata} download="socialshare.png" />
      : 
      <div className='socialshareimgloading'>
        <img src={loadingsmall} className='loadingsmall'  />
      </div>
      }
      <span className='socialsharesmalltext' style={{marginTop: 8}}>{translate("tapholdtosave", props?.lang)}</span>
      </div>
      {/* END IMAGE TO DISPLAY */}

      {/* REAL IMAGE TO SHARE */}
      
      <div 
      ref={imageref}
      className='socialsharehidden'
      style={{ 
        backgroundImage: `url(${file ? file : props?.infos?.image})` ,
        width:1000,
        height: 1000,
        aspectRatio:"1 / 1",
        backgroundColor:"#eee",
        backgroundSize:"cover",
        backgroundPosition:"center",
        display: "block"
        }}>
          <img className='socialsharelogobig' src={`${process.env.REACT_APP_CONTENT_URL}${props.regionid}/logosocial.png`} />
        <div 
        style={{"position":"absolute","bottom":"-2px","width":"100%","padding":"56px","paddingTop":"110px","display":"flex","flexDirection":"row","alignItems":"center","background":"linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)","color":"#fff","boxSizing":"border-box"}}>
          <div 
          style={{backgroundImage: `url(${props?.infos?.badge})`, "width":"200px","height":"200px","backgroundSize":"cover","borderRadius":"100%","border":"8px solid #fff","marginRight":"12px","overflow":"hidden","position":"relative","flexShrink":"0","boxShadow":"0px 16px 32px #0005"}}
          >
            <img src={badgeshine}  style={{"width":"100%","position":"absolute","top":"0","left":"0"}} />
          </div>
          <div  style={{"marginLeft":"16px"}}>
            <p  style={{"fontFamily":"var(--font-display)","fontSize":"56px","lineHeight":"1.1","margin":"0px auto","marginBottom":"16px"}}>{props?.infos?.title}</p>
            {props.infos.done && <p  style={{"fontFamily":"var(--font-body)","fontSize":"28px","margin":"0px auto","opacity":"0.7"}}>{translate("badgedatetext", props?.lang)} {getDayMonth(props?.infos?.donedate)}</p>}
          </div>
        </div>
        
      </div>
      {/* END IMAGE TO SHARE */}

      <div className='modalsocialsharebuttons'>
        
        
        {file ?
        <div className='button-secondary clickable' onClick={() => setFile(null)}><img src={closeicon}/>{translate("removephoto", props?.lang)}</div>
        :
        <label className="custom-file-upload clickable">
        <input type="file" onChange={handleChange} accept="image/*" />
          <img src={cameraicon}/>{translate("usephoto", props?.lang)}
        </label>
        }
        <span className='socialsharesmalltext'>{translate("socialshareprivacy", props?.lang)}</span>

        <div className='button-primary' style={{marginTop: 20, opacity: imagedata ? 1 : 0.5}} onClick={() => imagedata ? handleShare() : null}><img src={shareicon} style={{width: 20, marginRight: 6, marginTop: -4}} />{translate("shareimage", props?.lang)}</div>
      </div>
      
        
 
      </div>
    </div>
  );
}

export default ModalSocialShare;
