import { useParams, useNavigate } from 'react-router-dom';

const handleApiResponse = (code, regionid, navigate) => {

    if (code === 1) {
      // Wrong region id
      navigate(`/`);
    } else if (code === 2) {
      // User (UUID) not found
      alert("User not found");
    } else if (code === 3) {
      // No regionuser profile
      navigate(`/${regionid}/start`);
    }
};
  
  export { handleApiResponse };
  