// translationHelper.js
import tl from "../translations";

export function translate(key, language) {
  const translation = tl[key];
  if (!translation) {
    // Handle the case when the key is not found
    console.warn(`Translation key '${key}' not found`);
    return '';
  }

  const translatedText = translation[language];
  if (!translatedText) {
    // Fallback to English if the language is not found for the given key
    console.warn(`Translation for key '${key}' in language '${language}' not found. Falling back to English.`);
    const englishTranslation = translation['en'];
    if (!englishTranslation) {
      console.warn(`Fallback to English failed. Translation for key '${key}' in English not found.`);
      return '';
    }

    return englishTranslation;
  }

  return translatedText;
}