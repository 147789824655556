// Video Player

import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player'

import playicon from "../img/play.svg"

function ContentVideo(props) {


  const [playing, setplaying] = useState(false);

  useEffect(() => {
    if(!props.active) {
      setplaying(false);
    }
  }, [props.active])

  return (
    <div className="page-content-video" onClick={() => setplaying(!playing)}>
      <ReactPlayer 
        playing={playing}
        url={props.baseurl + props.content.url} 
        width='100%'
        height='100%'
        playsinline
        loop
      />

      <div className={`videoplayicon ${playing ?  "videoplayiconhidden" : null}`}><img src={playicon} /></div>
    </div>
  );
}

export default ContentVideo;
