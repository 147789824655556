export default function getDayMonth(dateString) {
    // Parse the input date string
    const inputDate = new Date(dateString);
  
    // Get day and month components
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-indexed, so add 1
  
    // Format the output
    const formattedDate = `${day}.${month}`;
  
    return formattedDate;
  }