import { useState, useRef, useEffect } from 'react';
import { Outlet, useParams, useRouteError, useNavigate } from 'react-router-dom';

import { translate } from './functions/translationHelper';


function ErrorBoundary() {

  let error = useRouteError();
  console.log(error);

  const navigate = useNavigate();
  let { regionid } = useParams();

  let lang = localStorage.getItem("language") || "en";

  return (
  <div className='page-error'>
    <div className='wrapper'>
      <h2>{translate("errorheadline", lang)}</h2>
      <div className='button-primary' onClick={() => navigate(`/${regionid}`)}>{translate("errorbutton", lang)}</div>
    </div>
  </div>
  );
}

export default ErrorBoundary;
