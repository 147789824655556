

function Badgecounter(props) {
  return (
    <div className="badgecounter">
      <img style={{zIndex: 5}} className="badgesmall badgesmall-full" src={require("../img/badge1.png")} />
      <div style={{zIndex: 4}} className="badgesmall badgesmall-empty"></div>
      <div style={{zIndex: 3}} className="badgesmall badgesmall-empty"></div>
      <div style={{zIndex: 2}} className="badgesmall badgesmall-empty"></div>
      <div style={{zIndex: 1}} className="badgesmall badgesmall-empty"></div>
    </div>
  );
}

export default Badgecounter;
