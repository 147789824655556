import * as React from 'react';

import badgeshine from "../img/badgeshine.png";
import closeicon from "../img/close-white.svg";
import Badge from './Badge';

import badgeempty from "../img/badgeempty.svg";

import { translate } from '../functions/translationHelper';

import getDayMonth from '../functions/getDayMonth';

function reset() {
  localStorage.clear();
  window.location.reload();
}

function changeRegion() {
  localStorage.removeItem("region");
  window.location.replace("/");
}

function ModalBadges(props) {

  return (
    <div className={`modalbadge ${props.open ? null : "modalbadgeclosed"}`} >

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>

      <div className='badgecollectionsection' style={{ paddingTop: 24, paddingBottom: 40 }}>
        <div className='wrapper' style={{width: 'auto'}}>
          <h2 className='badgecollectionheadline'>{translate("stickers", props.lang)}</h2>

          {
            props.donetrails.length == 0 && <div className='badgecollectionemptystate'><div style={{ display: 'flex', position: 'absolute', top: -20, marginLeft: 24 }}>{props.trails.slice(0, 3).map((obj, index) => <div key={"emptystatebadge-" + index} style={{ zIndex: 4 - index }} className='badgecollectionemptystatebadge'><Badge img={obj.badge} active={true} /></div>)}</div><p>{translate("firststickertext", props.lang)}</p></div>
          }

          <div className='badgecollectionwrapper'>
            {
              props.donetrails.map((obj, index) => (
                <div className='badgecollectionitem' key={"badge-" + index} onClick={() => props.openshare(obj.id)}>
                  <Badge img={obj.badge} active={true} />
                  <p className='badgecollectionitemtitle'>{obj.title}</p>
                  <p className='badgecollectioniteminfotext'>{translate("badgedatetext", props.lang)} {getDayMonth(obj?.donedate)}</p>
                </div>
              ))
            }
            {
              [...Array(props.numberoftrails - props.donetrails.length)].map((obj, index) => (
                <div className='badgecollectionitem' key={"badgeempty-" + index}>
                  <div className='badgecollectionitemempty'><img src={badgeempty} /></div>
                </div>
              ))
            }





          </div>
          {process.env.NODE_ENV === 'development' &&
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 500, opacity: 0.6 }} onClick={() => reset()}>Reset progress</div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12, opacity: 0.6 }} onClick={() => changeRegion()}>Change region</div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12, opacity: 0.6 }} >Version: 0.1.2</div>
          </div>
          }
        </div>
      </div>

    </div>
  );
}

export default ModalBadges;
