import { useState, useRef, useEffect } from 'react';
import Map, { Marker, GeolocateControl } from 'react-map-gl/maplibre';

import badgeshine from "../img/badgeshine.png";
import closeicon from "../img/close-white.svg";

function ModalMap(props) {

  const mapref = useRef(null);

  let badgepos = JSON.parse(props.trails[0].mapbadgepos);

  function calculateBounds(trails) {
    if (!trails || trails.length === 0) {
      // Return some default values or handle the case when there are no trails
      return { minlong: 0, maxlong: 0, minlat: 0, maxlat: 0 };
    }

    let minlong, maxlong, minlat, maxlat;

    trails.forEach(trail => {
      // Parse the JSON string in mapbadgepos
      const mapBadgePos = JSON.parse(trail.mapbadgepos);

      // Extract lat and long
      const { lat, long } = mapBadgePos;

      // Initialize min and max values
      if (minlong === undefined || maxlong === undefined || minlat === undefined || maxlat === undefined) {
        minlong = maxlong = long;
        minlat = maxlat = lat;
      } else {
        // Update min and max values for longitude
        minlong = Math.min(minlong, long);
        maxlong = Math.max(maxlong, long);

        // Update min and max values for latitude
        minlat = Math.min(minlat, lat);
        maxlat = Math.max(maxlat, lat);
      }
    });

    return { minlong, maxlong, minlat, maxlat };
  }

  useEffect(() => {
    if (mapref.current != null && props.open) {
      if (props.trails.length > 1) {
        const bounds = calculateBounds(props.trails);
        mapref.current.fitBounds(
          [
            [bounds.minlong, bounds.minlat],
            [bounds.maxlong, bounds.maxlat]
          ],
          { padding: 80, duration: 1000 }
        );
      }
    }
  }, [mapref, props.open])

  return (
    <div className={`modalmap ${props.open ? null : "modalmapclosed"}`} >

      <div className='closebutton' onClick={props.onClose}><img src={closeicon} /></div>


      <Map
        ref={mapref}
        reuseMaps
        initialViewState={{
          longitude: badgepos.long,
          latitude: badgepos.lat,
          zoom: 12
        }}
        style={{ width: '100vw', height: '100vh', height: '100dvh' }}
        mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=${process.env.REACT_APP_MAP_KEY}`}
      >
        {
          props.trails.map((obj, index) => {
            let pos = JSON.parse(obj.mapbadgepos);
            return (<Marker key={index} onClick={() => props.onmarkerclick(index)} longitude={pos.long} latitude={pos.lat} anchor="center" >
              <div style={{ backgroundImage: `url(${obj.badge})` }} className='badge-detail badge-map'><img src={badgeshine} className="badgeshine" /></div>
            </Marker>)
          })
        }
        {/*
        <GeolocateControl
          position='bottom-left'
          style={{width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 40}}
      />*/}
      </Map>
    </div>
  );
}

export default ModalMap;
