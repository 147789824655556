// Camera wit sticker

import { useState, useRef, useEffect } from 'react';
import Webcam from "react-webcam";

function ContentCamera(props) {

  //const [zoom, setZoom] = useState(1);



  return (
    <div className="page-content-camera">
      {/*
      <input
        type='range'
        min={0.2}
        max={1}
        className='camerastickerslider'
        value={zoom}
        onChange={(e) => setZoom(e.target.value)}
  />*/}
      <img src={props.content.sticker} className='camerasticker' />
      {props.active &&
        <Webcam
          audio={false}
          videoConstraints={{ facingMode: "environment" }}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      }
    </div>
  );
}

export default ContentCamera;
