import notificationtemplates from "../notifications";
import close from "../img/close.svg";

function Notification(props) {

  let mytemplate = notificationtemplates[props.template];

  return (
    <div className='notification'>
      {mytemplate.icon &&<img className='notificationicon' src={mytemplate.icon} />}
      <div className="notificationtextcont">
        {mytemplate.headline && <div className="notificationheadline">{mytemplate.headline[props.lang]}</div>}
        {mytemplate.text && <div className="notificationtext">{mytemplate.text[props.lang]}</div>}
        {mytemplate.button && <div className="notificationbutton clickable" onClick={() => props.onButtonClick(props.template)}>{mytemplate.button[props.lang]}</div>}
      </div>

      {mytemplate.dismissable && <img className='notificationcloseicon' onClick={() => props.onRemove()} src={close} />}
      
    </div>
  );
}

export default Notification;
