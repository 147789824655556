import { useState, useRef, useEffect } from 'react';
import './App.css';

// import clouds1 from "./img/clouds1.png";
// import clouds2 from "./img/clouds2.png";
// import clouds3 from "./img/clouds3.png";



function LoadingFade(props) {

  return (
    // props.clouds ?
    // <div className="loadingfadeclouds">
    //   <div className='cloudloadingrect'></div>
    //   <div className="cloudswrapper">
    //     <img src={clouds1} className='loadingcloud clouds1' />
    //     <img src={clouds2} className='loadingcloud clouds2' />
    //     <img src={clouds3} className='loadingcloud clouds3' />
    //   </div>
    // </div>
    // :
    // <div className="loadingfade">
    // </div>
    <div className="loadingfade">
    </div>
  );
}

export default LoadingFade;
