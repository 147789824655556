import tags from "../tags";

function Tag(props) {

  return (
    <div className={`tag tag-${props.tag}`}>
      {tags[props.tag]?.icon && <img src={tags[props.tag]?.icon} />}
      {tags[props.tag]?.translations[props.lang]}
    </div>
  );
}

export default Tag;
