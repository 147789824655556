import { useState, useRef, useEffect } from 'react';
import closeicon from "../img/close.svg";

import { translate } from '../functions/translationHelper';

import { useParams, useNavigate } from 'react-router-dom';

import ioslocationicon from "../img/location-ios.png";
import androidlocationicon from "../img/location-android.png";


function ModalLocationInfo(props) {

  let { regionid, trailid } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("ios");

  let lang = props.lang;

  const tl = {
    headline: {
      de: "Standort freigeben",
      en: "Enable your location",
      it: "Abilita la tua posizione"
    },
    subtext: {
      de: "Um Stationen freizuschalten musst du dich ihnen nähern. Dafür benötigt die App deinen Standort.",
      en: "To unlock the stations you need to move close to them, and for that the app needs to know your location.",
      it: "Per sbloccare le stazioni devi avvicinarti a loro, e per questo l'app ha bisogno di conoscere la tua posizione."
    },
    iphone: {
      de: ["Öffne die Einstellungen","Tippe auf Datenschutz", "Tippe auf Standort", "Schalte Standort ein (grün)"],
      en: ["Open your iPhone's Settings","Tap Privacy", "Tap Location Services", "Move slider to on (green)"],
      it: ["Apri le Impostazioni del tuo iPhone","Tocca Privacy", "Tocca Servizi di localizzazione", "Sposta il cursore su on (verde)"]
    },
    android: {
      de: ["Öffne die Einstellungen","Tippe auf Standort", "Schalte Standort ein"],
      en: ["Open your Phone's Settings","Tap Location", "Move the slider to On"],
      it: ["Apri le Impostazioni del tuo telefono","Tocca Posizione", "Sposta il cursore su On"]
    },
    reload: {
      de: "Neu laden wenn fertig",
      en: "Reload page when done",
      it: "Ricarica la pagina quando hai finito"
    }
  }


  return (
    <div className={`modalstationprogress ${props.open ? null : "modalstationprogressclosed"}`} >
      <div className="modalstationprogressbackground" onClick={() => props.onClose()}></div>
      <div className="modallocationinner" >
      

      <img src={closeicon} className='modalstationprogressclose' onClick={() => props.onClose()} />
        
        <div className="modallocationinnercontent">
          <h2>{tl.headline[lang]}</h2>
          <p>{tl.subtext[lang]}</p>

          <div className='tabcontainer'>
            <div className={`tabitem ${tab == "ios" && "tabitemactive"}`} onClick={() => setTab("ios")}>iPhone</div>
            <div className={`tabitem ${tab == "android" && "tabitemactive"}`} onClick={() => setTab("android")}>Android</div>
          </div>

          {
            tab == "ios" &&
            <div>
              <ol>
                <li>{tl.iphone[lang][0]}</li>
                <li>{tl.iphone[lang][1]}</li>
                <li>{tl.iphone[lang][2]}</li>
                <li>{tl.iphone[lang][3]}</li>
              </ol>
              <img src={ioslocationicon} style={{width: '100%', maxWidth: 280, marginTop: 12}} />
            </div>
            
          }
          {
            tab == "android" &&
            <div>
              <ol>
                <li>{tl.android[lang][0]}</li>
                <li>{tl.android[lang][1]}</li>
                <li>{tl.android[lang][2]}</li>
              </ol>
              <img src={androidlocationicon} style={{width: '100%', maxWidth: 240, marginTop: 12}} />
            </div>
          }

          <div className='button-primary' style={{marginTop: 24}} onClick={() => window.location.reload()}>{tl.reload[lang]}</div>
          
        </div>

      </div>
    </div>
  );
}

export default ModalLocationInfo;
