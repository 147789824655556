import badgeshine from "../img/badgeshine.png";

function Badge(props) {
  return (
    <div className={`badge ${props.active ? "badgedone" : "badgeinactive"}`} style={{backgroundImage: `url(${props.img})` }}>
        <img src={badgeshine} className="badgeshine" />
    </div>
  );
}

export default Badge;
