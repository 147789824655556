

function PositionDot(props) {
  return (
    <div className={"positiondotcont"}>
        <div className={"positiondotcircle"}></div>
        <div className={"positiondot"}></div>
    </div>
  );
}

export default PositionDot;
