// Audio Player with second buttons

import { useState, useRef, useEffect } from 'react';


function ContentExternal(props) {

  
  useEffect(() => {
    if (!props.active) {
      
    }
  }, [props.active])

  return (
    <div className="page-content-external">
      <iframe src={props.content.url}></iframe>

    </div>
  );
}

export default ContentExternal;
