// Success page and Feedback

import { useState, useRef, useEffect, useMemo } from 'react';
import './success.css';
import badgeshine from "./img/badgeshine.png";
import badgesuccessshine from "./img/badgesuccessshine.svg";
import LoadingScreen from './LoadingScreen';
import LoadingFade from './LoadingFade';

import ModalFeedback from './components/ModalFeedback';

import Confetti from 'react-confetti';

import { translate } from './functions/translationHelper';

import audio from './img/sounds/success.wav';
import { handleApiResponse } from './functions/handleApiResponse.js';
import { useParams, useNavigate } from 'react-router-dom';

function Success() {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  let { regionid, trailid } = useParams();
  const navigate = useNavigate();

  //const myAudio = useMemo(() => new Audio(audio), [audio]);

  const [feedbackopen, setFeedbackopen] = useState(false);

  const mockdata = {
    title: "Niederthai Challenge",
    stations: 9
  }


  function collectSticker() {
    //myAudio.play();
    setFeedbackopen(true);
  }

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}getBadge.php?region=${regionid}&trailid=${trailid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('uuid')}`,
        }
      }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      if(data.code !== undefined) {
        handleApiResponse(data.code, regionid, navigate);
      } else {
        if(data.success) {
          setData(data);
          setLoading(false);
          localStorage.setItem("badgenotification", true);
        } else {
          navigate(`/${regionid}`);
        }
      }
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    loading ? <LoadingScreen/> : (
    <div className="page-success">
       <LoadingFade/>
      <Confetti
        numberOfPieces={60}
        style={{zIndex: 4}}
      />
      {/* Success */}
      <div className='wrapper wrapper-success'>
        <div className='badgesuccesswrapper'>
          <div className={"badge badgesuccess2"} style={{backgroundImage: `url(${data.badge_url})`, backgroundColor: '#000'}}>
            <img src={badgeshine} className="badgeshine" />
            <div className='badgesuccessdarken'></div>
          </div>
          <img src={badgesuccessshine} className="badgesuccessshine" />
        </div>
        <div className='badgesuccesstext'>
          <h2>{translate("success_headline", data.language)}</h2>
          <p>{translate("success_text", data.language).replace('${1}', data.trail_title)}</p>
          <div className='button-primary' style={{marginTop: 28}} onClick={() => collectSticker()}>{translate("collect_sticker", data.language)}</div>
        </div>
      </div>

      <ModalFeedback trailid={trailid} lang={data.language} open={feedbackopen} onClose={() => setFeedbackopen(false)}  />
      
    </div>
    )
  );
}

export default Success;
