// Start page where user selects language

import { useState, useRef, useEffect } from 'react';
import languageinfo from './languageinfo';
import './langswitcher.css';
import { translate } from './functions/translationHelper';
import LoadingScreen from './LoadingScreen';

import { useParams, useNavigate } from 'react-router-dom';


function LanguagePicker() {

  let { regionid } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState();

const mockdata = [
  {language: "de", headline: "Entdecke Ötztal", privacylink: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals"},
  {language: "en", headline: "Discover Ötztal", privacylink: "https://www.google.com/maps/d/u/1/"},
]

function selectcurrentlanguage(mydata) {
  let currentlanguage = localStorage.getItem("language");
  let newindex = mydata.findIndex(item => item.language === currentlanguage);
  if(newindex >= 0) {
    setIndex(newindex);
  }
}

useEffect(() => {
  
  fetch(`${process.env.REACT_APP_API_URL}getLanguageData.php?region=${regionid}`)
  .then(response => response.json())
  .then(data => {
    console.log(data);
    setData(data);
    selectcurrentlanguage(data);
    setLoading(false);
  })
  .catch(error => {
    console.error('Error fetching language data:', error.message);
    // Handle the error, e.g., set an error state
  });
},[])

const creatRegionUser = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}createRegionUser.php`, {
      method: 'POST',
      body: JSON.stringify({uuid: localStorage.getItem('uuid'), region: regionid, language: data[index].language})
    });

    const res = await response.json();

    console.log(res);
    localStorage.setItem("language", data[index].language);
    
    if(res?.success) {
      navigate(`/${regionid}`);
    }

  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

  return (
    loading ? <LoadingScreen /> :
    data.length > 0 ?
    <div className="page-languagepicker">
      <div className='wrapper languagepickerwrapper'>
        <h1>{data[index].headline}</h1>
        <div className={`languagelist ${(data.length == 3 || data.length >= 5) && "languagelist-3"}`}>
          {
            data.map((obj, i) => <div key={i} className={`languageswitchercard clickable ${i == index && "languageswitchercard-active"}`} onClick={() => setIndex(i)}><img src={languageinfo[obj.language].image} /><p>{languageinfo[obj.language].name}</p></div>)
          }
        </div>
        <div>
          <p className='consenttext'>{translate("consenttext", data[index].language)} <a href={data[index].privacylink || "https://www.wunderweg.com/privacy-policy/"} target='_blank'>{translate("consentbutton", data[index].language)}</a></p>
          <div onClick={() => creatRegionUser()} className='button-primary'>{translate("letsgo", data[index].language)}</div>
        </div>
        
      </div>
    </div>
    : <div>no results</div>
  );
}

export default LanguagePicker;
