import { useState, useRef, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';


function RegionRoot() {

  const { regionid } = useParams();

  useEffect(() => {
    // Load external style based on the URL parameter
    if (regionid) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${process.env.REACT_APP_CONTENT_URL}${regionid}/style.css`;  // Adjust the path accordingly
      document.body.appendChild(link);

      // Dynamically change the manifest.json link
      const manifestLink = document.querySelector('link[rel="manifest"]');
      if (manifestLink) {
        manifestLink.href = `${process.env.REACT_APP_CONTENT_URL}${regionid}/manifest.json`;
      }

      // Dynamically change the favicon link
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = `${process.env.REACT_APP_CONTENT_URL}${regionid}/favicon.ico`;
      }

      // Dynamically change the apple icon link
      const appletouchicon = document.querySelector('link[rel="apple-touch-icon"]');
      if (appletouchicon) {
        appletouchicon.href = `${process.env.REACT_APP_CONTENT_URL}${regionid}/logo.png`;
      }

      const fetchManifestData = async () => {
        try {
          // Fetch manifest.json data
          const response = await fetch(`${process.env.REACT_APP_CONTENT_URL}${regionid}/manifest.json`);
          const manifestData = await response.json();
  
          // Dynamically change the title and description
          document.title = manifestData.name || 'Default Title';
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.content = manifestData.description || 'Your adventures';
          }
          const themecolor = document.querySelector('meta[name="theme-color"]');
          if (themecolor) {
            themecolor.content = manifestData.theme_color || '#fff';
          }
        } catch (error) {
          console.error('Error fetching manifest.json:', error);
        }
      };

      fetchManifestData();
    }
  }, [regionid]); // Run this effect only once on component mount

  return <Outlet />;
}

export default RegionRoot;
