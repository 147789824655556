import { useState, useRef, useEffect } from 'react';
import './App.css';
import Overview from './Overviewnew3';
import TrailDetail from './TrailDetail';
import MapNavigation from './MapNavigationnew2';
import Station from './Station2';
import Success from './Success-new';
import LanguagePicker from './LanguagePicker';
import RegionRoot from './RegionRoot';
import ErrorBoundary from './ErrorBoundary';
import LoadingScreen from './LoadingScreen';
import RegionOverview from './RegionOverview';

import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
  BrowserRouter,
  useParams,
  redirect,
  Link,

} from "react-router-dom";




function App() {

  const loggedin = localStorage.getItem('uuid') != null;
  
  const router = createBrowserRouter([
    {
      path: "/:regionid",
      element: <RegionRoot/>,
      errorElement: <ErrorBoundary/>,
      children: [
        {
          path: "",
          element: <Overview/>,
        },
        {
          path: "start",
          element: <LanguagePicker/>,
        },
        
        {
          path: "map/:trailid",
          element: <MapNavigation/>,
        },
        {
          path: "station/:stationid",
          element: <Station/>,
        },
        {
          path: "success/:trailid",
          element: <Success/>,
        },
      ]
    },
    {
      path: "/",
      element: <RegionOverview/>,
      errorElement: <ErrorBoundary/>,
    }
  ]);

  const login = createBrowserRouter([
    {
      path: "*",
      element: <LoadingScreen newuser/>,
    },
  ])


  return (
    
    loggedin ?
    <RouterProvider router={router} />
    :
    <RouterProvider router={login} />
  );
}



export default App;
