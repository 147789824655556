import { useState } from 'react';

function ContentQuiz(props) {

  const data = props.content;

  const [attempts, setAttempts] = useState([]);

  const handleOptionClick = (index) => {
    const selectedOption = data.options[index];
    const isCorrect = selectedOption.correct;

    if(isCorrect) {
      props.onCompletion();
    }

    // Check if the user has already found the correct answer for this question
    const hasFoundCorrectAnswer = attempts.some(attempt => attempt.isCorrect);

    // Only allow new selections if the user hasn't found the correct answer yet
    if (!hasFoundCorrectAnswer) {
      setAttempts([...attempts, { index, isCorrect }]);
    }
  };

  return (
    <div className="page-content-quiz">
      <div className='page-content-quiz-wrapper'>
        <h1>{data.question}</h1>

        <div className={`quiz-box ${data.twocolumn && "quiz-box-two"}`}>
          {
            data.options.map((obj, index) => {
              const selectedAttempt = attempts.find(attempt => attempt.index === index);
              const isSelected = selectedAttempt !== undefined;
              const isCorrect = selectedAttempt ? selectedAttempt.isCorrect : false;

              return (
                <div
                  key={index}
                  className={`quiz-item ${isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''}`}
                  onClick={() => handleOptionClick(index)}
                >
                  {obj.image && <div className='quizitemimage' style={{backgroundImage: `url(${props.baseurl + obj.image})`, backgroundSize: obj.imagecover ? 'cover' : 'contain'}}></div>}
                  {obj.text &&<div className='quizitemtext' style={{fontSize: obj.smalltext && 14, padding: obj.smalltext && 8}}>{obj.text}</div>}
                </div>
              );
            })
          }
        </div>
        {/*<div onClick={() => setAttempts([])}>Reset</div>*/}
      </div>
    </div>
  );
}

export default ContentQuiz;
