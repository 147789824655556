import starimg from "../img/star.svg";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function MapProgress(props) {
  return (
    <div className="mapprogresscontainer">
        <div className="mapprogressstations">
          <CircularProgressbarWithChildren 
            value={props.done} 
            maxValue={props.total}
            strokeWidth={6}
            styles={{
              root: {overflow: 'visible'},
              path: {
                stroke: 'var(--main-color)',
                strokeWidth: 8
              },
              trail: {
                stroke: '#e3e3e3',
              },
            }}
          >
          <div className="mapprogresstext"><span>{props.done}</span>/{props.total}</div>
          </CircularProgressbarWithChildren>
          
        </div>
        {/* for later
        <div className="mapprogresspoints">
          <img src={starimg} />
          {props.points}
        </div>
        */}
    </div>
  );
}

export default MapProgress;
